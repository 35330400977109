import {
  Button,
  Form,
  Input, message,
  Select
} from "antd";
import React, { useState } from 'react';
import ModalWrapper from 'app/__portions/ModalWrapper';
import { ITreeGranularity } from "../../../../typings";
import Api from "../../../../Services";
import ENDPOINTS from "../../../../Services/endpoints";
import Storage from "../../../../utils/helpers/Storage";
import { USER_ROLES } from "../../../../utils/helpers/constants";
import RestrictedTo from "../../../../__portions/RestrictedTo";
import keys from "../../../../utils/configs/keys";

type Props = {
  visible : boolean
  selected: boolean
  data   ?: ITreeGranularity | undefined
  setVisible: (boolean) => void
  reload  : () => void
}

const DialogEditTree = ({visible, selected, data, setVisible, reload}:Props) => {
  const [form] = Form.useForm();
  const [performing, setPerforming] = useState(false);
  const [performingSelection, setPerformingSelection] = useState(false);

  const initialValues = {
    name    : data?.name,
    branches: data?.branches
  }
  const onClose = () => {
    setVisible(false)
  };

  const onEditTree = async (values) => {
    const { error } = await Api.put(`${ENDPOINTS.TREE_CRU}/${data?.id}`, values);

    if (error) {
      if (
        Object.keys(error).length &&
        Object.getPrototypeOf(error) === Object.prototype
      ) {
        Object.keys(error).forEach((err) => {
          message.error(error[err][0]);
        });
        setPerforming(false);
        return;
      }
      message.error(
        'Failed to update user information. Please try again',
      );
      setPerforming(false);
      return;
    }

    setPerforming(false);
    onClose()
    reload()
  };

  const onFinish = async (values) => {
    setPerforming(true);
    await onEditTree(values);
  };

  const handleTreeSelection = async () => {
    setPerformingSelection(true);
    const payload = { "tree_id": data?.id }

    const { error } = await Api.post(`${ENDPOINTS.TREE_CRU}/select`, payload);

    if (error) {
      if (
        Object.keys(error).length &&
        Object.getPrototypeOf(error) === Object.prototype
      ) {
        Object.keys(error).forEach((err) => {
          message.error(error[err][0]);
        });
        setPerformingSelection(false);
        return;
      }
      message.error(
        'Failed to update user information. Please try again',
      );
      setPerformingSelection(false);
      return;
    }

    Storage.setSelectedTreeItemId(data?.id)
    const branches = data?.branches
    branches?.push('item')
    Storage.setSelectedTreeItemBranches(JSON.stringify(branches))

    Storage.remove(keys.TREE_SELECTION_KEY)
    Storage.remove(keys.SELECTED_TREE_KEY)
    Storage.remove(keys.EXPANDED_TREE_KEY)

    setPerformingSelection(false);
    onClose()
  };

  return (
    <ModalWrapper width={450} visible={visible} onCancel={onClose}>
      <h3 className='font-bold'>Update tree</h3>
      <Form
        form={form}
        layout="vertical"
        requiredMark
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <div className="flex flex-col items-center justify-between">
          <Form.Item
            label="Name"
            required
            tooltip="Name is required"
            name="name"
            className="w-full"
            hasFeedback
            rules={[
              { required: true, message: "Please input the tree name!" },
            ]}
          >
            <Input
              placeholder="Name"
              className="w-full border-none border-1 rounded-sm p-1"
            />
          </Form.Item>
          <Form.Item
            label="Branches"
            required
            tooltip="Branches is required"
            name="branches"
            className="w-full"
            hasFeedback
            rules={[
              { required: true, message: "Please input tree branches!" },
            ]}
          >
            <Select
              mode="tags"
              size='large'
              tokenSeparators={[',']}
              className="w-full border-none rounded-sm p-0"
            />
          </Form.Item>
        </div>

        <div className="flex items-center justify-between gap-2">
          <Button
            type="dashed"
            className="rounded-md w-full btn-height px-3"
            disabled={performingSelection || performing}
            onClick={onClose}
          >
            Cancel
          </Button>
          <RestrictedTo to={[USER_ROLES.Planner.value, USER_ROLES.Sales.value]}>
            {!selected && (
              <Button
                htmlType="button"
                type="ghost"
                className="rounded-md w-full btn-height px-3 ml-3"
                disabled={performingSelection || performing}
                loading ={performingSelection}
                onClick={handleTreeSelection}
              >
                Select
              </Button>
            )}
            <Button
              htmlType="submit"
              type="primary"
              className="rounded-md w-full btn-height px-3 ml-3"
              disabled={performing || performingSelection}
              loading ={performing}
            >
              Save
            </Button>
          </RestrictedTo>
        </div>
      </Form>
    </ModalWrapper>
  );
};


DialogEditTree.defaultProps = {
  data: null,
};
export default DialogEditTree;
