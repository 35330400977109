import jwtDecode from 'jwt-decode';
import Storage from './Storage';

interface TokenType {
  exp: number;
}

const isExpired = (token = Storage.getAccessToken()) => {
  if (!token) {
    return true;
  }
  try {
    const decoded: TokenType = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime + 5;
  } catch (error) {
    return true;
  }
};
export default isExpired;
