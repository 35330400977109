const ENDPOINTS = {
  LOGIN: '/auth/login',
  REFRESH: '/auth/refresh',
  LOG_OUT: '/auth/logout',

  TREE_CRU: '/auth/tree',
  TREE_LIST: '/auth/trees',

  APP_SETTINGS: '/auth/app-settings',
  TREE_NAVIGATOR: '/core/fetch/tree',
  FETCH_DATA: '/core/fetch/data',
  FETCH_FVA: '/core/fva/calculate',
  UPLOAD: '/core/upload?type=',
  UPLOAD_HISTORY: '/core/uploaded/files',
  FETCH_ANALYTICS: '/core/fetch/analytics',
  EXPORT_CSV: '/core/csv_report',
  PLANNING_FORECAST: '/core/planning/forecast',
  USERS: '/auth/users',
  PREVIOUS_CYCLE: '/core/planning/previous_adj',
  CAPACITY_PLANNING: '/core/planning/capacity',
  UPLOAD_IMAGE: 'https://fs.xeco.be/api/upload',
};

export default ENDPOINTS;
