import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { FaArrowLeftLong } from "react-icons/fa6";
import { PlusCircleFilled, CheckCircleFilled } from "@ant-design/icons";

import Scaffold from 'app/__portions/Scaffold';
import RestrictedTo from "app/__portions/RestrictedTo";
import CardHorizon from "./components/CardHorizon";
import ENDPOINTS from "../../Services/endpoints";
import Api from "../../Services";
import CardGranularity from "./components/CardGranularity";
import { GranularitySelectionType } from "../../bloc/atoms";
import Storage from "../../utils/helpers/Storage";
import SettingSideMenu from "../../__portions/SideMenus/SettingSideMenu";

import {
  defaultHorizonSelection,
  defaultHorizonUnit,
  getGranularityData,
  getSelectedGranularity,
  handleHorizonList
} from "../../utils/helpers";

import TreeItem from "./components/TreeItem";
import DialogAddTree from "./components/DialogAddTree";
import DialogEditTree from "./components/DialogEditTree";
import { EditTreeProps, ITreeGranularity } from "../../typings";
import { USER_ROLES } from "../../utils/helpers/constants";
import useDemand from "../../Hooks/useDemand";

const Settings = () => {
  const [, setGranularitySelection] = useRecoilState(GranularitySelectionType);
  const [selected, setSelected] = useState(Storage.getGranularity);
  const [selectedHorizon, setSelectedHorizon] = useState(Storage.getHorizonIndex);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState<EditTreeProps>({ visible: false, selected: false });
  const [trees, setTrees] = useState<ITreeGranularity[]>([]);
  const history = useHistory();
  const { onHorizonChange } = useDemand();

  const changeGranularity = async (index: string) => {
    setLoading(true)
    const payload = {
      granularity         : getSelectedGranularity(index),
      adjustment_lock_days: Storage.getAdjustmentLockDays(),
      tree_id             : Storage.getSelectedTreeItemId() || 1,
    }

    await Api.put(ENDPOINTS.APP_SETTINGS, payload).then(() => {
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      message.error(error)
    });
  }

  const fetchData = async () => {
    setLoading(true);
    const { data, error } = await Api.get(ENDPOINTS.TREE_LIST);
    setLoading(false);
    setTrees(data)

    if (error) {
      message.error(error)
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const reload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  }

  const handleReloadPrevious = async () => {
    await changeGranularity(selected).then(()=> {
      history.goBack();
      reload()
    })
  };

  const handleHorizon = async (horizon: string) => {
    setSelectedHorizon(horizon)
    Storage.setHorizonIndex(horizon)

    await onHorizonChange(Number(horizon))
  }

  const handlerGranularity = async (index: string) => {
    setSelected(index);
    Storage.setGranularity(index);
    setGranularitySelection(getSelectedGranularity(index))

    await handleHorizon(`${defaultHorizonSelection().value}`)
  }

  const onBackPress = () => {
    history.goBack();
  }

  const handleAddTree = () => {
    setVisible(!visible)
  }

  const handleEditTree = (tree:ITreeGranularity) => {
    setVisibleEdit((prev) => ({
      ...prev,
      visible: true,
      data: tree,
    }));
  }

  const handleVisibleEdit = (visible: boolean) => {
    setVisibleEdit((prev) => ({
      ...prev,
      visible,
    }))
  }

  const isTreeSelected = (id: number|undefined) : boolean => {
    const treeId = Storage.getSelectedTreeItemId() || 1
    return treeId ? Number.parseInt(treeId, 10) === id : false
  }

  return (
    <Scaffold sideBar={<SettingSideMenu />}>
      <div className="ant-col ant-space-align-center mb-4">
        <Button type='default' className='flex align-center justify-between text-primary rounded-md' onClick={onBackPress}>
          <FaArrowLeftLong width={32} height={32} className='mr-2'/>
          Back
        </Button>
      </div>
      <h1 className="text-lg font-bold letter-28 title">SETTINGS</h1>
      <div className="card rounded-md p-4 flex flex-nowrap">
        <div className="flex-1 animate-all-500 p-4">
            <span className="title font-bold text-xl">
              Granularity
            </span>
          <div className='mt-1 p-2 display-grid'>
            {getGranularityData.map((value) => (
              <CardGranularity
                key={`granularity_key_${value.index}`}
                icon={<value.icon size="72px" />}
                title={value.title}
                index={value.index}
                loading={loading}
                selected={selected === `${value.index}`}
                onSelect={(index) => handlerGranularity(index)}
              />
            ))}
          </div>

          <div className="mt-3">
            <div className="title font-bold text-xl">
              Forecast Horizon
            </div>
            <div className="mt-1 p-2 display-grid">
              {handleHorizonList().map((value) => (
                <CardHorizon
                  key={`horizon_key_${value}`}
                  title={`${value}`}
                  format={defaultHorizonUnit()}
                  index={`${value}`}
                  selected={selectedHorizon === `${value}`}
                  onSelect={(index) => handleHorizon(index)}
                />
              ))}
            </div>
          </div>

          <div className="mt-3">
            <div className="title font-bold text-xl">
              Trees
            </div>
            <div className="mt-1 p-2 display-grid-horizon gap-4">
              {trees.map((tree) => (
                <div className='flex flex-col' key={tree.id}>
                  <div style={{display: "flex", alignItems: "start", justifyItems: "center", gap: '8px'}}>
                    <h3>{tree.name}</h3>
                    {isTreeSelected(tree?.id) && (
                      <div className="text-blue text-xl">
                        <CheckCircleFilled/>
                      </div>
                    )}
                  </div>
                  <TreeItem tree={tree}/>
                  <Button className='text-blue mt-3 text-md rounded-md bg-gray-50 w-80' onClick={() => handleEditTree(tree)}>Edit</Button>
                </div>
              ))}
              <RestrictedTo to={[USER_ROLES.Planner.value, USER_ROLES.Sales.value]}>
                <button type='submit' className="min-width-button-add-tree bg-white flex flex-col items-center justify-center border border-blue rounded-md" onClick={handleAddTree}>
                  <div className="text-xl text-blue">
                    <PlusCircleFilled/>
                  </div>
                  <span className='text-blue mt-3 text-md'>Add tree</span>
                </button>
              </RestrictedTo>

            </div>
          </div>

          <Button
            type="primary"
            className="items-center rounded-md h-full btn-height min-width-button mt-3"
            onClick={handleReloadPrevious}>Save</Button>
        </div>
      </div>
      <DialogAddTree
        visible={visible}
        reload={reload}
        setVisible={setVisible}/>

      <DialogEditTree
        visible={visibleEdit.visible}
        reload={reload}
        setVisible={handleVisibleEdit}
        selected={isTreeSelected(visibleEdit?.data?.id)}
        data={visibleEdit.data}/>
    </Scaffold>
  );
};

export default Settings;

