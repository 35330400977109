import React, { useEffect } from "react";
import { Avatar, Dropdown } from "antd";
import { AiOutlineUser } from 'react-icons/ai';
import { useRecoilState } from "recoil";
import { FiPieChart } from "react-icons/fi";
import { HiOutlineDownload } from 'react-icons/hi';
import { IoDocumentTextOutline } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';
import { WiBarometer } from 'react-icons/wi';
import { BsBadgeAd, BsCalendarWeek } from "react-icons/bs";
import UserActionsOverlay from 'app/__portions/Dropdowns/userActions';
import RestrictedTo from 'app/__portions/RestrictedTo';
import useLogout from 'app/Hooks/useLogout';
import DownloadActions from 'app/__portions/Dropdowns/downloadActions';
import { USER_ROLES } from 'app/utils/helpers/constants';
import DropdownArrow from 'app/__portions/_drawables/DropdownArrow';
import paths from 'app/routes/paths';
import UploadDropdown from '../UploadDropdown';
import { themeState } from "../../../bloc/atoms";

const TopNavbar = () => {
  const [theme] = useRecoilState(themeState);
  const { pathname } = useLocation();
  const onLogout = useLogout();

  useEffect(() => {
    document.documentElement.classList.toggle('dark-theme', theme === 'dark');
  }, [theme]);

  return (
    <div className="flex card">
      <Link key="report" to={paths.REPORT_PATH}
        className={`ant-row ant-space-align-center ant-row-no-wrap text-black hover:text-blue mr-2 ${
          pathname === paths.REPORT_PATH ? 'border-b-2 border-blue text-blue' : ''
        }`}
      >
        <IoDocumentTextOutline size="19.2px" />
        <div className="px-1 title">Report</div>
      </Link>
      <Link key="planning" to={paths.DEMAND_PLANNING}
        className={`ant-row ant-space-align-center ant-row-no-wrap text-black hover:text-blue mr-2 ${
          pathname === paths.DEMAND_PLANNING ? 'border-b-2 border-blue text-blue' : ''
        }`}
      >
        <BsCalendarWeek size="16px" />
        <div className="px-1 title">Demand</div>
      </Link>
      <Link key="fva" to={paths.FORECAST_VALUE_ADDED}
        className={`ant-row ant-space-align-center ant-row-no-wrap text-black hover:text-blue mr-2 ${
          pathname === paths.FORECAST_VALUE_ADDED ? 'border-b-2 border-blue text-blue' : ''
        }`}
      >
        <BsBadgeAd size="16px" />
        <div className="px-1 title">FVA</div>
      </Link>
      <RestrictedTo key="analytics" to={[USER_ROLES.Sales.value]}>
        <Link
          to={paths.REPORT_ANALYTICS}
          className={`ant-row ant-space-align-center ant-row-no-wrap text-black hover:text-blue ${
            pathname.includes(paths.REPORT_ANALYTICS) ? 'border-b-2 border-blue text-blue' : ''
          }`}
        >
          <FiPieChart size="19.2px" />
          <div className="px-1 title">Analytics</div>
        </Link>
      </RestrictedTo>

      <Link key="capacity" to={paths.CAPACITY_DEMAND}
        className={`ant-row ant-space-align-center ant-row-no-wrap text-black hover:text-blue ${
          pathname === paths.CAPACITY_DEMAND ? 'border-b-2 border-blue text-blue' : ''
        }`}
      >
        <WiBarometer size="24px" />
        <div className="px-1 title">Capacity</div>
      </Link>
      <RestrictedTo key="uploads" to={[USER_ROLES.Sales.value]}>
        <div className="ant-row ant-space-align-center border-l border-gray-100">
          <Dropdown key="download" overlay={DownloadActions} className="pointer p-2">
            <div
              key="upload"
              className="ant-row ant-space-align-center ant-row-no-wrap hover:text-blue"
            >
              <HiOutlineDownload size="16px" style={{ marginRight: 4 }} />
              <DropdownArrow />
            </div>
          </Dropdown>
        </div>
        <UploadDropdown key="upload" />
      </RestrictedTo>
      <div className="ant-row ant-space-align-center" key="user details">
        <Dropdown overlay={<UserActionsOverlay onLogout={onLogout} />}>
          <div className="flex items-center">
            <Avatar
              icon={<AiOutlineUser />}
              size="small"
              className="border"
              style={{ marginRight: 6 }}
            />
            <DropdownArrow />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

TopNavbar.defaultProps = {
  hideSideBarInMedium: false,
};

export default TopNavbar;
