import React, { useCallback, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useRecoilState, useRecoilValue } from 'recoil';
import Scaffold from 'app/__portions/Scaffold';
import {
  CapacityState,
  selectedKeysState,
  SelectedNode,
  TreeDataState,
} from 'app/bloc/atoms';
import Api from 'app/Services';
import ENDPOINTS from 'app/Services/endpoints';
import { DAILY_DATE_FORMAT } from 'app/utils/helpers/constants';
import CapacityTable from './Table';
import CapacityChart from './Chart';
import { getSelectorLines } from "../../utils/helpers/elementizers";

const { RangePicker } = DatePicker;

const CapacityDemand = () => {
  const [{ dateFilter, hasLoaded }, setCapacityPlanning] = useRecoilState(CapacityState);
  const { treeDataResponse, treeData } = useRecoilValue(TreeDataState);
  const [{ capacityKeys: selectedKey}, setSelectedKeys] = useRecoilState(selectedKeysState);
  const [{ analyticsNode: selectedNode }, setSelectedNode] = useRecoilState(SelectedNode);

  const fetchCapacity = useCallback(
    async (selector = getSelectorLines(selectedKey, treeData), dateRange = dateFilter) => {
      if (!selector) return;

      const payload = {
        selector,
        start_date: moment(`${dateRange[0]}-01`).format(
          DAILY_DATE_FORMAT,
        ),
        end_date: moment(`${dateRange[1]}-01`).format(
          DAILY_DATE_FORMAT,
        ),
      };

      setCapacityPlanning((val) => ({ ...val, loading: true }));

      const { data, error } = await Api.post(
        ENDPOINTS.CAPACITY_PLANNING,
        payload,
      );

      if (data) {
        setCapacityPlanning((val) => ({
          ...val,
          capacities: data,
          hasLoaded: true,
          loading: false,
        }));
        return;
      }

      if (error) {
        setCapacityPlanning((val) => ({
          ...val,
          hasLoaded: true,
          loading: false,
        }));
      }
    },
    [dateFilter, setCapacityPlanning, treeDataResponse],
  );

  const onSelect = useCallback(async (keys, info) => {

      setSelectedKeys((prev) => ({ ...prev, capacityKeys: info.node.key }))
      setSelectedNode((prev) => ({ ...prev, capacityNode: info.node }));

      const selector = getSelectorLines(info.node.key, treeData, true)

      // const lines = getPayloadLines(
      //   info.node,
      //   treeDataResponse,
      // ).filter((line) => line.name === 'Cartridges');

      setCapacityPlanning((val) => ({
        ...val,
        capacities: {
          items: 1,
          days: [],
          months: [],
          weeks: [],
        },
      }));

      await fetchCapacity(selector);
    },
    [
      fetchCapacity,
      setCapacityPlanning,
      setSelectedKeys,
      setSelectedNode,
      treeDataResponse,
    ],
  );

  const onDateChange = useCallback(
    async (_, formatString) => {
      setCapacityPlanning((val) => ({
        ...val,
        dateFilter: formatString,
        capacities: {
          items: 1,
          days: [],
          months: [],
          weeks: [],
        },
      }));
      const selector = getSelectorLines(selectedKey, treeDataResponse);

      await fetchCapacity(selector, formatString);
    },
    [
      fetchCapacity,
      selectedNode,
      setCapacityPlanning,
      treeData,
      treeDataResponse,
    ],
  );

  useEffect(() => {
    if (!hasLoaded) {
      fetchCapacity();
    }
  }, [fetchCapacity, hasLoaded]);

    return (
    <Scaffold onSelect={onSelect}>
        <div className="ant-row ant-row-space-between ant-space-align-center">
          <h1 className="text-lg letter-28 text-black-50">
          CAPACITY PLANNING
        </h1>
          <RangePicker
              picker="month"
              value={[moment(dateFilter[0]), moment(dateFilter[1])]}
              onChange={onDateChange}
              className="rounded-md border-w-2 btn-height"
          />
      </div>

      <div className="bg-white shadow-sm rounded-md p-5 my-5">
        <CapacityTable />
        <CapacityChart />
      </div>
    </Scaffold>
  );
};

export default CapacityDemand;
