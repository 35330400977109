import React, { Key, useEffect } from "react";
import { Skeleton, Space, Tree } from 'antd';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from "recoil";

import { TAny } from "app/typings";
import { TreeDataState } from "app/bloc/atoms";
import useTreeData from 'app/Hooks/useTreeData';
import Storage from "../../utils/helpers/Storage";
import paths from "../../routes/paths";
import { getDefaultKeys } from "../../utils/helpers/elementizers";

interface Props {
  onSelect?: (keys: Key[], info: TAny) => void;
  onExpand?: (keys: Key[], info: TAny) => void;
}

const TreeNavigator = ({ onSelect, onExpand }: Props) => {
  const { treeData, loading, hasLoaded } = useRecoilValue(TreeDataState);
  const [fetchTreeData] = useTreeData();
  const location = useLocation();

  const selectedKey = Storage.getSelectedTreeKey();
  const expandedKey = Storage.getExpandedTreeKey();

  const { defaultSelected, defaultExpanded } = getDefaultKeys(treeData);

  const finalSelected = selectedKey || defaultSelected;
  const finalExpanded = expandedKey || defaultExpanded;
  const onSelectTree = (keys: Key[], info: TAny) => {
    onSelect?.(keys, info);
  };
  const onExpandTree = (keys: Key[], info: TAny) => {
    Storage.setExpandedTreeKey(JSON.stringify(keys))
    onExpand?.(keys, info);
  };

  useEffect(() => {
    if (!hasLoaded) {
      fetchTreeData();
    }
  }, [fetchTreeData, hasLoaded]);

  return (
    <div className="py-4 px-1">
      {(location.pathname === paths.DEMAND_PLANNING) && (
        <div className="ant-row ant-space-align-center">
          <div style={{ width: '10px', height: '10px', borderRadius: '10px', backgroundColor: 'green' }} />
          <div style={{ marginLeft: '0.5rem' }}>Demand Planning</div>
        </div>
      )}
      {loading ? (
        <>
          {Array.from({ length: 15 }).map((_, index) => (
            <Space key={index.toFixed()} className="flex items-center justify-between mb-2">
              <Skeleton.Input className='rounded-sm' active size="small"/>
              <Skeleton.Input className='rounded-sm' active size="small"/>
            </Space>
          ))}
        </>
      ) : (
        <Tree
          showLine
          blockNode
          treeData={treeData}
          defaultSelectedKeys={finalSelected}
          defaultExpandedKeys={finalExpanded}
          onSelect={onSelectTree}
          onExpand={onExpandTree}
        />
      )}
    </div>
  );
};

TreeNavigator.defaultProps = {
  onSelect: undefined,
  onExpand: undefined,
};

export default TreeNavigator;
