import React from 'react';
import { Row } from 'antd';
import { IoAnalyticsSharp } from 'react-icons/io5';
import SwitcherIcon from 'app/__portions/SwitcherIcon';
import { ITree, TAny } from "app/typings";
import Storage from "./Storage";

export const buildTree = (
  data,
  keys: string[],
  level = 0,
  parentKey = 'all-products',
  parentName: string | null = null,
  parentType: string | null = null,
  colors = ["#01459a", "#0158c5", "#0287af", "#03c9f1", "#9de1ee", "#90e0ef"]
): ITree[] => {
  if (level >= keys.length) return [];

  const currentKey = keys[level];
  const nextKey = keys[level + 1];

  return data?.map((node, index) => {
    const currentNodeKey = `${parentKey ? `${parentKey}-` : ''}${currentKey}-${node.name}-${index}`;
    const color = colors[level % colors.length];

    const children = buildTree(
      node[nextKey],
      keys,
      level + 1,
      currentNodeKey,
      node.name,
      currentKey,
      colors
    );

    return {
      switcherIcon: () => <SwitcherIcon color={color} />,
      key     : currentNodeKey,
      type    : currentKey,
      title   : node.name,
      name    : node.name,
      parent  : parentKey ? { key: parentKey, type: parentType, name: parentName } : null,
      children: children.length ? children : undefined,
    };
  });
};

export const findNodeByKey = (key: string, lines?: TAny) => {
  for (const line of Object.values(lines!)) {
    // @ts-ignore
    if (line.key === key) {
      return line;
    }
    // @ts-ignore
    if (line.children) {
      // @ts-ignore
      const found = findNodeByKey(key, line.children);
      if (found) return found;
    }
  }

  return null;
};

const getNodePath = (treeDataKey: string[], node: TAny, lines: TAny) => {
  const path = treeDataKey.reduce((acc, key) => {
    acc[key] = null;
    return acc;
  }, {} as Record<string, string | null>);

  let current = node;

  while (current) {
    const { type, name, parent } = current;
    path[type] = name;
    current = parent ? findNodeByKey(parent.key, lines) : null;
  }

  return path;
};

const getExpandedKeys = (node: TAny, path: string[] = []): string[] => {
  if (!node?.children || node.children.length === 0) {
    return path;
  }

  const currentPath = [...path, node.key];
  return getExpandedKeys(node.children[0], currentPath);
};

const getLastChildKey = (node: TAny): string | null => {
  if (!node?.children || node.children.length === 0) {
    return node?.key || null;
  }
  return getLastChildKey(node.children[node.children.length - 1]);
};

export const getDefaultKeys = (treeData: TAny[]) => {
  if (!treeData || treeData.length === 0) {
    return { defaultSelected: [], defaultExpanded: [] };
  }

  const firstNode = treeData[0]?.children[0];
  const defaultExpanded = getExpandedKeys(firstNode);
  const lastChildKey = getLastChildKey(firstNode);

  return {
    defaultSelected: lastChildKey ? [lastChildKey] : [],
    defaultExpanded,
  };
};

export const handleTreeSelection = (
  treeDataKey: string[],
  key: string,
  lines: TAny
) => {
  if (!treeDataKey) return null

  let node:TAny

  if(key){
     node = findNodeByKey(key, lines);
  } else {
    const {defaultSelected} = getDefaultKeys(lines)
    node = findNodeByKey(defaultSelected[0], lines);
  }

  if (!node) return null

  return getNodePath(treeDataKey, node, lines);
};


export const getSelectorLines = (keys?:string, lines?:TAny, onSelect = false) => {
  const treeDataKey = Storage.getSelectedTreeItemBranches();

  if(!onSelect && (Storage.getTreeSelection() !== null)){
    return Storage.getTreeSelection()
  }

  const selection = handleTreeSelection(treeDataKey, keys!, lines!)
  if(!(selection) || selection['all-products']) if (selection) {
    delete selection["all-products"];
  }

  return selection
}

export const allProductsNode = {
  switcherIcon: () => <SwitcherIcon/>,
  key: 'all-products',
  title: (
    <Row justify="space-between" align="middle">
      <div>All Sales</div>
      <IoAnalyticsSharp size="16px" />
    </Row>
  ),
  name: 'All Sales',
  selected: false,
  type: 'all-products'
} as TAny;