import React from "react";
import { Button, Menu } from "antd";
import {
  downloadActualTemplate, downloadBudgetTemplate,
  downloadForecastTemplate
} from "../../utils/helpers";

const DownloadActions = () => {
  return (
    <Menu className="border rounded-md">
      <Menu.Item key="download actuals template">
        <Button type="text" onClick={downloadActualTemplate}>
          Actuals template
        </Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="download forecast template">
        <Button type="text" onClick={downloadForecastTemplate}>
          Forecast template
        </Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="download budget template">
        <Button type="text" onClick={downloadBudgetTemplate}>
          Budget template
        </Button>
      </Menu.Item>
    </Menu>
  );
};

export default DownloadActions;
