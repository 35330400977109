import { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ErrorsState, TreeDataState } from 'app/bloc/atoms';
import Api from 'app/Services';
import ENDPOINTS from 'app/Services/endpoints';
import { allProductsNode, buildTree } from "app/utils/helpers/elementizers";
import Storage from "../utils/helpers/Storage";

const useTreeData = (): [() => Promise<void>] => {
  const [{ treeData }, setTreeDataState] = useRecoilState(TreeDataState);
  const setErrorState = useSetRecoilState(ErrorsState);

  const fetchTreeData = useCallback(async () => {
    if (!treeData?.length) {
      setTreeDataState((state) => ({
        ...state,
        loading: true,
      }));

      const { data, status, error, message } = await Api.get(
        ENDPOINTS.TREE_NAVIGATOR,
      );

      if (error && message) {
        setErrorState((errors) => ({
          ...errors,
          data: { error, message },
          isServerDown: true
        }));

        setTreeDataState((state) => ({
          ...state,
          loading: false,
        }));
        return;
      }

      if (status === 404 || status === 500) {
        setTreeDataState((state) => ({
          ...state,
          loading: false,
        }));
        return;
      }

      const treeDataKey = Storage.getSelectedTreeItemBranches();

      if (data && treeDataKey) {
        const linesTree = buildTree(data[treeDataKey[0]], treeDataKey);
        Object.assign(allProductsNode, { children: linesTree });

        setTreeDataState((state) => ({
          ...state,
          treeData: [allProductsNode],
          treeDataResponse: data,
          loading: false,
          hasLoaded: false,
        }));

        setErrorState({
          data: {
            error: null,
            message: null,
          },
          isServerDown: false,
        });
      } else {
        setErrorState((errors) => ({
          ...errors,
          data: {
            error  : 'Tree selection',
            message: 'Please select a Tree in the setting page to be able to use the app',
            action : 'Go to settings'
          },
          isTreeSelected: true,
        }));
      }
    }
  }, [setErrorState, setTreeDataState, treeData]);

  return [fetchTreeData];
};

export default useTreeData;
