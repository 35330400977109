import React from 'react';
import { Result } from 'antd';
import {
  Link,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';
import IRoute from 'app/typings';
import Storage from 'app/utils/helpers/Storage';
import isExpired from 'app/utils/helpers/isExpired';
import { currentRole } from 'app/utils/configs';
import paths from './paths';

interface IProps {
  routes: IRoute[];
}

const Routes = ({ routes }:IProps) => {
  const { pathname } = window.location;

  const render = (route: IRoute) => {
    const path =
      (Array.isArray(route.path) &&
        route.path[route.path.indexOf(pathname)]) ||
      (route.path as string);

    return (
      <Route
        key={`route_${routes.indexOf(route)}`}
        exact={route.exact}
        path={path}
        render={(props: RouteComponentProps) => {
          const token = Storage.getAccessToken();
          const role = currentRole();

          if (route.secured && !token && isExpired()) {
            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location },
                }}
                exact
              />
            );
          }

          // REDIRECT TO REPORT PAGE IF TOKEN EXISTS
          if (['/', ''].includes(path) && token)
            return <Redirect to={paths.REPORT_PATH} />;

          if (role && route.roles && !route.roles.includes(role?.value)) {
            return (
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Link type="primary" to="/">
                    Back Home
                  </Link>
                }
              />
            );
          }

          // @ts-ignore
            return <route.component {...props} />;
        }}
      />
    );
  };
  return <Switch>{routes.map((route) => render(route))}</Switch>;
};

export default Routes;
