import { Col } from "antd";
import Storage from "../../../utils/helpers/Storage";

interface Props {
  title?   : string;
  format?  : string;
  index?   : string;
  selected?: boolean;
  onSelect?: (key: string) => void;
}

const CardHorizon = ({title, format, index, onSelect, selected }:Props) => {
  return (
    <Col onClick={() => onSelect ? onSelect(index!) : null} >
      <div className={`display-grid-item-horizon flex-col p-1 flex-center rounded-md hover:text-blue ${(selected) ? 'border-2-blue selected-text-blue bg-blue-transparent' : 'border bg-primary'}`}>
        <span className='font-bold text-xl'>{title}</span>
        <span className='text-sm'>{format}</span>
      </div>
    </Col>
  )
}

CardHorizon.defaultProps = {
  title   : '30',
  format  : 'Daily',
  index   : Storage.getGranularity(),
  selected: false,
  onSelect: () => null,
};

export default CardHorizon