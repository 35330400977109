import './styles.css';

import { Avatar, Button, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { FaArrowLeftLong } from "react-icons/fa6";
import { HiOutlinePencil } from "react-icons/hi";
import { UploadFile } from "antd/lib/upload/interface";
import Api from "../../../Services";
import DialogUpdateUser from "./DialogUpdateUser";
import ENDPOINTS from "../../../Services/endpoints";
import Storage from "../../../utils/helpers/Storage";
import Scaffold from "../../../__portions/Scaffold";
import SettingSideMenu from "../../../__portions/SideMenus/SettingSideMenu";
import { UserState } from "../../../bloc/atoms";
import keys from "../../../utils/configs/keys";

import { IUserTableData, TAny } from "../../../typings";

const Profile = () => {
  const history = useHistory();
  const [fileList, setFileList] = useState<UploadFile<TAny>[]>([]);
  const [{ currentUser }, setCreateModal] = useRecoilState(UserState);

  const getUserData = async () => {
    await Api.get(`${ENDPOINTS.USERS}/${Storage.get(keys.USER_ID)}`)
      .then(body => {
        setCreateModal((prev) => ({
          ...prev,
          currentUser: body.data as Partial<IUserTableData>
        }));
      })
      .catch((error) => {
        message.error(error)
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const showUpdateDialog = () => {
    setCreateModal((prev) => ({
      ...prev,
      isEdit: true,
    }));
  }

  const uploadProps = {
    fileList,
    showUploadList: false,
    accept: ['image/png', 'image/jpeg', 'image/webp'].join(),
    beforeUpload: (file) => {
      if (file.size > 1024 * 1024) {
        message.warning(
          'Image is too large, please upload an image of maximum 1MB',
        );
        return false;
      }
      setFileList([file]);
      return false;
    },
  };

  const onBackPress = () => {
    history.push('/');
  }

  return (
    <Scaffold sideBar={<SettingSideMenu />}>
      <div className="ant-col ant-space-align-center mb-4">
        <Button type='default' className='flex align-center text-primary' shape='round' onClick={onBackPress}>
          <FaArrowLeftLong width={32} height={32} className='mr-2'/>
          Back
        </Button>
      </div>
      <h1 className="text-lg font-bold letter-28 text-black">PROFILE</h1>

      <div className="bg-white rounded-md p-4 flex flex-nowrap">
        <div className="flex-1 animate-all-500 p-4">
          <span className="text-black font-bold text-lg">User information</span>
          <div className='mt-3 p-4 flex items-center'>
            <div className="rounded-md w-[400px] h-[400px]">
              <div className="wrapper">
                <div className="border rounded-md p-1 relative">
                  <Upload
                    className="change-profile-button border"
                    listType="picture"
                    {...uploadProps}
                  >
                    <HiOutlinePencil className="text-black-50" />
                  </Upload>
                  <Avatar
                    size={200}
                    shape="square"
                    className="rounded-md bg-[#deefff]"
                    src={currentUser?.profile_image}
                  />
                </div>
                <div className='divider'/>
                <div className="wrapper-info">
                  <div className="wrapper-content">
                    <div className="profile-item">
                      <span className="profile-item-label">First name</span>
                      <span className="profile-item-data">{currentUser?.first_name || '-'}</span>
                    </div>
                    <div className="profile-item">
                      <span className="profile-item-label">Last name</span>
                      <span className="profile-item-data">{currentUser?.last_name || '-'}</span>
                    </div>
                  </div>
                  <div className="profile-item mt-5">
                    <span className="profile-item-label">Email</span>
                    <span className="profile-item-data">{currentUser?.email || '-'}</span>
                  </div>
                  <div className="wrapper-content mt-5">
                    <div className="profile-item">
                      <span className="profile-item-label">Role</span>
                      <span className="profile-item-data">{currentUser?.role || '-'}</span>
                    </div>
                    <Button type='primary' size="large" onClick={() => showUpdateDialog()}>Update</Button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <DialogUpdateUser />
    </Scaffold>
  )
}
export default Profile;