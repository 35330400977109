import { Tree } from 'antd';
import React from 'react';

const { DirectoryTree } = Tree;


const TreeItem = ({tree}) => {

  const generateTreeData = (branches: string[]) => {
    if (branches.length === 0) return [];

    const [parent, ...rest] = branches;

    return [
      {
        title   : parent,
        key     : parent,
        children: generateTreeData(rest),
        isLeaf  : branches.length === 0
      }
    ];
  };

  // @ts-ignore
  const treeData = generateTreeData(tree?.branches);

  return (
    <DirectoryTree
      multiple
      defaultExpandAll
      treeData={treeData}
    />
  );
};

export default TreeItem;