import moment from "moment";
import readXlsxFile, { Row } from "read-excel-file";
import { TAny } from "app/typings";
import { ValidationError, generateDateValueMap, getGranularity, getSelectedGranularityValue } from "./index";

import { DAILY_DATE_FORMAT } from "./constants";
import Storage from "./Storage";

const excelNumberToDate = (value: unknown) => {
  const isDate = value instanceof Date && !Number.isNaN(value);
  if (isDate) {
    return moment(value).format(DAILY_DATE_FORMAT);
  }
  if (typeof value === 'number') {
    return moment(new Date(Date.UTC(0, 0, value - 1))).format(
      DAILY_DATE_FORMAT,
    );
  }
  return value;
};


const getTreeBranchesName = (row: Row, keys:string[], isForecast = false) => {
  const branches = {}
  const start = isForecast ? 1 : 0

  const branchValues = keys.filter(item => item !== 'item');

  for (let i = 0; i < branchValues.length; i += 1) {
    branches[keys[i]] = row[i + start] || 'N/A';
  }

  return { branches }
}

export const getPayload = async (file: File, name: string, type: string) => {
  try {
    const rows= await readXlsxFile(file);

    const startIndex= rows.findIndex((row: Array<TAny>) => {
      return row.some((value: TAny) => value === "Description");
    });

    const branches = Storage.getSelectedTreeItemBranches()
    const keys:string[] = Array.from(branches)

    const isForecast= type === 'forecast';
    const dateColumnsStart = isForecast ? keys.length + 2 : keys.length + 1;

    const datesRow = rows[startIndex].slice(dateColumnsStart).map(excelNumberToDate);
    rows.splice(0, startIndex + 1);

    type IProps = { date : string, value: number }

    return rows.map((row, index) => {
      let data
      const dataWeekMonth: IProps[] = []

      switch (getSelectedGranularityValue()) {
        case "daily":
          data = row
            .slice(dateColumnsStart)
            .map((value, index) => {
              return {
                date: datesRow[index] as string,
                value: typeof value === 'number' ? Math.trunc(value) : 0,
              };
            })
            .filter((d) => d.date);
          break;
        case "weekly":
        case "monthly":
          row
            .slice(dateColumnsStart)
            // eslint-disable-next-line array-callback-return
            .map((value, index) => {
              const d = generateDateValueMap({
                date: datesRow[index] as string,
                value: typeof value === 'number' ? Math.trunc(value) : 0,
              })
              // @ts-ignore
              dataWeekMonth.push(...d)
            })
            // @ts-ignore
            .filter((d) => d?.date);
          break;
        default:
          data = row
            .slice(dateColumnsStart)
            .map((value, index) => {
              return {
                date: datesRow[index] as string,
                value: typeof value === 'number' ? Math.trunc(value) : 0,
              };
            })
            .filter((d) => d.date);
      }

      if (isForecast) {
        const madeOn = excelNumberToDate(row[0])
        if (madeOn === row[0]) {
          throw new ValidationError(`The 'Forecast ${getGranularity()}' is required and must be a date on row ${index + 1}`)
        }

        const { branches} = getTreeBranchesName(rows[index], keys, isForecast)
        return {
          made_on      : madeOn,
          branch_values: branches,
          item         : row[keys.length]     || 'N/A',
          description  : row[keys.length + 1] || 'N/A',
          data         : getGranularity() === 'date' ? data : dataWeekMonth,
          file_name    : name,
        }
      }

      const { branches} = getTreeBranchesName(rows[index], keys)
      return {
        branch_values: branches,
        item         : row[keys.length - 1] || 'N/A',
        description  : row[keys.length]     || 'N/A',
        data         : getGranularity() === 'date' ? data : dataWeekMonth,
        file_name    : name,
      }
    })
  } catch (error) {
    console.error(error)
    return Promise.reject(error)
  }
}