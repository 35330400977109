import moment from 'moment';
import {
  IDemandPlanning,
  INode,
  TPredictionModel,
} from 'app/typings';
import {
  DAILY_DATE_FORMAT,
  PERFORMANCE_CHOICES,
  PREDICTION_MODELS,
} from 'app/utils/helpers/constants';
import { defaultHorizonSelection } from "../utils/helpers";

export const DEMAND_PLANNING_DEFAULT: IDemandPlanning = {
  loading: false,
  hasLoaded: false,
  horizon: defaultHorizonSelection().value,
  currentCycle: moment().format(DAILY_DATE_FORMAT),
  performance: PERFORMANCE_CHOICES.Machine.value,
  data: {
    columns: [],
    rowData: [],
    accuracy: 0,
    metric: 'RMSE',
    consensus: {
      salesConsensus: [
        {
          name: PREDICTION_MODELS.HWM.value as TPredictionModel,
          accuracy: 0,
          RMSE: 0,
          forecast: {},
          description: PREDICTION_MODELS.HWM.name,
        },
      ],
      plannerConsensus: [],
    },
    adjustments: {
      actualAdjts: {},
      plannerAdjts: {},
      salesAdjts: {},
    },
    model: {
      name: PREDICTION_MODELS.HWM.value as TPredictionModel,
      accuracy: 0,
      RMSE: 0,
      forecast: {},
      description: PREDICTION_MODELS.HWM.name,
    },
    graphData: {
      labels: [],
      datasets: [{ label: 'Actual', data: [] }],
    },
  },
};

export const DEFAULT_SELECTED_NODE: Partial<INode> = {};

export const DEFAULT_SELECTION_KEYS = {
  reportKeys   : '',
  analyticsKeys: '',
  fvaKeys      : '',
  planningKeys : '',
  capacityKeys : '',
};
