import moment from "moment";
import { BsCalendar, BsCalendarMonth, BsCalendarWeek } from "react-icons/bs";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import {
  dailyHorizon,
  IPayloadProps,
  ITemplateGranularity,
  monthlyHorizon,
  RowType,
  TAny,
  TForecastTableType,
  TGranularityDateFormat,
  TGranularityDateFormatDisplay,
  TGranularitySelectionType,
  TGranularityType,
  TGranularityTypeDay,
  weeklyHorizon
} from "app/typings";
import {
  DAILY_DATE_FORMAT,
  DAILY_DISPLAY_FORMAT,
  MONTHLY_DATE_FORMAT,
  MONTHLY_DISPLAY_FORMAT,
  WEEKLY_DATE_FORMAT,
  WEEKLY_DISPLAY_FORMAT
} from "./constants";

import { groupByMonth, groupByWeek } from "../../pages/Settings/Utils/WeekDataConverter";
import Storage from "./Storage";

const collect =
  (pred: TAny) =>
    (xs = []) =>
      xs.flatMap((x: TAny) => [
        ...(pred(x) ? [x] : []),
        ...collect(pred)(x?.children),
      ]);

export const getSelectedKeys = (treeData: TAny) =>
  collect(({ selected }) => selected)(treeData).map(
    (value: TAny) => value.key,
  );

export const getSelectedNodeByKeys = (key: TAny) : boolean => {
  const branches = Storage.getSelectedTreeItemBranches()
  const keys = Array.from(branches)

  return <boolean>keys.find((_key) => _key === key)
};

export const isObject = (value: TAny): boolean => {
  return value?.constructor === Object;
};

export const sumArrayObjectsValues = (arr = []) => {
  const result = {};

  arr.forEach((obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (result[key]) {
        result[key] += value;
      } else {
        result[key] = value;
      }
    });
  });
  return result;
};

export const analyticsQuarters = {
  Q1: ['Jan', 'Feb', 'Mar'],
  Q2: ['Apr', 'May', 'Jun'],
  Q3: ['Jul', 'Aug', 'Sep'],
  Q4: ['Oct', 'Nov', 'Dec'],
};

export const aggregateQuarters = (data: TAny, year: TAny, field = '') => {
  const obj = {} as TAny;
  let currFiscTotal = 0;
  let nextFiscTotal = 0;

  const dataKeys = Object.keys(analyticsQuarters);

  if (!Object.keys(data).length) {
    return {};
  }

  dataKeys.forEach((quarterKey, index) => {
    let currQuarterTotal = 0;
    let nextQuarterTotal = 0;

    analyticsQuarters[quarterKey].forEach((quarter: TAny) => {
      if (data[`${year}-${quarter}`]) {
        currQuarterTotal += data[`${year}-${quarter}`];
      }
      if (data[`${+year + 1}-${quarter}`]) {
        nextQuarterTotal += data[`${+year + 1}-${quarter}`];
      }
    });

    obj[`cfy${quarterKey}`] = currQuarterTotal;
    obj[`nfy${quarterKey}`] = nextQuarterTotal;

    currFiscTotal += currQuarterTotal;
    nextFiscTotal += nextQuarterTotal;

    obj.cfyFY = currFiscTotal;
    obj.nfyFY = nextFiscTotal;
    obj.key = `${field}-${quarterKey}-${index}`;
    obj.field = field === 'Id' ? 'ID' : field || undefined;
  });

  return obj;
};

export const getDaysBetweenDates = (
  dateStart: moment.Moment,
  dateEnd: moment.Moment,
  format = DAILY_DISPLAY_FORMAT,
) => {
  const interim = dateStart.clone();
  const dayIntervals: string[] = [];

  while (dateEnd > interim || interim.format('D') === dateEnd.format('D')) {
    dayIntervals.push(interim.format(format));
    interim.add(1, 'day');
  }
  return dayIntervals;
};

export const getWeeksBetweenDates = (
  dateStart: moment.Moment,
  dateEnd: moment.Moment,
  format = WEEKLY_DISPLAY_FORMAT
) => {
  const result: string[] = [];

  // Adjust start date to the nearest Monday if it's not already a Monday
  const start = dateStart.clone().startOf('isoWeek');

  // Adjust end date to the nearest Sunday if it's not already a Sunday
  const end = dateEnd.clone().endOf('isoWeek');

  const interim = start.clone();

  while (interim.isSameOrBefore(end)) {
    const weekStart = interim.clone();
    const weekEnd = moment.min(interim.clone().endOf('isoWeek'), end);

    result.push(`${weekStart.format(format)}->${weekEnd.format(format)}`);

    interim.add(1, 'week'); // Move to the next week
  }

  return result;
};

export const getMonthsBetweenDates = (
  dateStart: moment.Moment,
  dateEnd: moment.Moment,
  format = MONTHLY_DISPLAY_FORMAT,
) => {
  const interim = dateStart.clone();
  const timeValues: string[] = [];

  while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
    timeValues.push(interim.format(format));
    interim.add(1, 'month');
  }

  return timeValues;
};

export const getDayWeekMonthBetweenDates = (granularity: string, range: string[]) => {
  switch (granularity) {
    case '1': return getDaysBetweenDates(moment(range[0]), moment(range[1]))
    case '2': return getWeeksBetweenDates(moment(range[0]), moment(range[1]))
    case '3': return getMonthsBetweenDates(moment(range[0]), moment(range[1]))
    default: return getDaysBetweenDates(moment(range[0]), moment(range[1]))
  }
}

export const bgColor = (value, record) => {
  if (isObject(value) && value.highlight) {
    return 'lightblue';
  }
  if (value !== undefined && record.key === 'actual highlight') {
    return '#04abd3';
  }
  if (record.key?.includes('bg-gray')) {
    return '#fafafa';
  }
  return '';
};

export const getGranularityDateFormatDisplay = () => {
  let granularity: TGranularityDateFormatDisplay;
  const index = Storage.getGranularity()
  switch (index) {
    case '1': granularity = DAILY_DISPLAY_FORMAT
      break;
    case '2': granularity = WEEKLY_DISPLAY_FORMAT
      break;
    case '3': granularity = MONTHLY_DISPLAY_FORMAT
      break;
    default: granularity = DAILY_DISPLAY_FORMAT
  }

  return granularity
}

export const sortByDateAfter = (unordered = {}) => {
  return Object.keys(unordered)
    .sort((a, b) =>
      moment(a, getGranularityDateFormatDisplay()).isAfter(moment(b, getGranularityDateFormatDisplay()))
        ? 1
        : -1,
    )
    .reduce((ordered, key) => {
      ordered[key] = unordered[key];
      return ordered;
    }, {});
};

export const getPreviousDay = (format = DAILY_DATE_FORMAT) => {
  return moment()
    .subtract(1, 'day')
    .startOf('day')
    .format(format);
};

export const toUser = (datum) => ({
  key: datum.id,
  info: {
    firstName: datum.first_name,
    lastName: datum.last_name,
    avatarUrl: datum.profile_image,
  },
  role: datum.role,
  email: datum.email,
  isStaff: datum.is_staff,
});

export const staleAndWait = (ms = 1000) => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isEmpty = (obj: object) => obj && Object.keys(obj).length && Object.getPrototypeOf(obj) === Object.prototype;

function formatDate(dateString: string) {
  const months = {
    'Jan': '01',
    'Feb': '02',
    'Mar': '03',
    'Apr': '04',
    'May': '05',
    'Jun': '06',
    'Jul': '07',
    'Aug': '08',
    'Sep': '09',
    'Oct': '10',
    'Nov': '11',
    'Dec': '12'
  };

  const [year, month, day] = dateString.split('-');
  const formattedMonth = months[month];
  return `${year}-${formattedMonth}-${day}`;
}

export class ValidationError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ValidationError'
  }
}

export const getSelectedGranularity = (index: string) => {
  let granularity: TGranularitySelectionType;
  switch (index) {
    case '1':
      granularity = 'daily'
      break;
    case '2':
      granularity = 'weekly'
      break;
    case '3':
      granularity = 'monthly'
      break;
    default: granularity = 'daily'
  }

  return granularity
}

export const transformedAdjustment = (adjustement) => {
  return Object.keys(adjustement).reduce((acc, date) => {
    acc[date] = adjustement[date].value;
    return acc;
  }, {});
}

export const getSelectedGranularityValue = () => {
  let granularity: TGranularitySelectionType;
  const index = Storage.getGranularity()
  switch (index) {
    case '1':
      granularity = 'daily'
      break;
    case '2':
      granularity = 'weekly'
      break;
    case '3':
      granularity = 'monthly'
      break;
    default: granularity = 'daily'
  }

  return granularity
}
export const getGranularity = () => {
  let granularity: TGranularityType;
  const index = Storage.getGranularity()
  switch (index) {
    case '1':
      granularity = 'date'
      break;
    case '2':
      granularity = 'week'
      break;
    case '3':
      granularity = 'month'
      break;
    default: granularity = 'date'
  }

  return granularity
}

export const getGranularityDay = () => {
  let granularity: TGranularityTypeDay;
  const index = Storage.getGranularity()
  switch (index) {
    case '1':
      granularity = 'day'
      break;
    case '2':
      granularity = 'week'
      break;
    case '3':
      granularity = 'month'
      break;
    default: granularity = 'day'
  }

  return granularity
}

export const getGranularityDateFormat = () => {
  let granularity: TGranularityDateFormat;
  const index = Storage.getGranularity()
  switch (index) {
    case '1':
      granularity = DAILY_DATE_FORMAT
      break;
    case '2':
      granularity = WEEKLY_DATE_FORMAT
      break;
    case '3':
      granularity = MONTHLY_DATE_FORMAT
      break;
    default: granularity = DAILY_DATE_FORMAT
  }

  return granularity
}
export const getColumnRanges = (start: string, end: string) => {
  switch (getGranularity()) {
    case "date": return getDaysBetweenDates(
      moment(start, DAILY_DATE_FORMAT),
      moment(end, DAILY_DATE_FORMAT),
    )
    case "week": return getWeeksBetweenDates(
      moment(start, WEEKLY_DATE_FORMAT),
      moment(end, WEEKLY_DATE_FORMAT),
    )
    case "month": return getMonthsBetweenDates(
      moment(start, MONTHLY_DATE_FORMAT),
      moment(end, MONTHLY_DATE_FORMAT),
    )
    default: return getDaysBetweenDates(
      moment(start, DAILY_DATE_FORMAT),
      moment(end, DAILY_DATE_FORMAT),
    )
  }
}
export const getMadeOnDays = (madeOnStartDay: moment.Moment, madeOnEndDay: moment.Moment) => {
  switch (getGranularity()) {
    case "date":
      return getDaysBetweenDates(madeOnStartDay, madeOnEndDay)
    case "week":
      return getWeeksBetweenDates(madeOnStartDay, madeOnEndDay)
    case "month":
      return getMonthsBetweenDates(madeOnStartDay, madeOnEndDay)
    default:
      return getDaysBetweenDates(madeOnStartDay, madeOnEndDay)
  }
}

type IPayload = {
  date: string,
  value: number,
  message: string
}

export const getActualRow = (actual: TAny) => {
  let data: TAny
  let value: TAny
  switch (getSelectedGranularityValue()) {
    case 'daily': return { key: 'actual highlight', reportTitle: 'Actual', ...actual };
    case 'weekly':
      data = groupByWeek(actual)
      value = data.reduce((acc: TAny, item: TAny) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});

      return { key: 'actual highlight', reportTitle: 'Actual', ...value };
    case 'monthly':
      data = groupByMonth({data: actual})
      value = data.reduce((acc: TAny, item: TAny) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});
      return { key: 'actual highlight', reportTitle: 'Actual', ...value };
    default: return { key: 'actual highlight', reportTitle: 'Actual', ...actual };
  }
}
export const getForecastRow = (forecast: object, columnsRange: string[], madeOnDays: string[]) => {
  const forecastRows = [] as RowType[];
  let row: TAny
  let value: TAny
  switch (getSelectedGranularityValue()) {
    case 'daily':
      madeOnDays.forEach((day, index) => {
        row = forecast[day];
        value = row?.[columnsRange[index]];
        if (row && value !== undefined) {
          const madeOnRow = {
            key: `made on ${day}`,
            reportTitle: day,
            // @ts-ignore
            ...row,
            [columnsRange[index]]: { highlight: true, value },
          } as RowType;

          forecastRows.push(madeOnRow);
          delete forecast[day];
        }
      });
      break;
    case 'weekly':
      madeOnDays.forEach((day, index) => {
        const data = groupByWeek(forecast)
        const dataWeek = data.reduce((acc, item) => {
          const key = Object.keys(item)[0];
          acc[key] = item[key];
          return acc;
        }, {});

        row = dataWeek[day]
        value = row?.[columnsRange[index]];
        if (row && value !== undefined) {
          const madeOnRow = {
            key: `made on ${day}`,
            reportTitle: day,
            // @ts-ignore
            ...row,
            [columnsRange[index]]: { highlight: true, value },
          } as RowType;

          forecastRows.push(madeOnRow);
          delete forecast[day];
        }
      });
      break;
    case 'monthly':
      madeOnDays.forEach((day, index) => {
        const weekly = groupByWeek(forecast)
        row = weekly![day];
        value = row?.[columnsRange[index]];
        if (row && value !== undefined) {
          const madeOnRow = {
            key: `made on ${day}`,
            reportTitle: day,
            // @ts-ignore
            ...row,
            [columnsRange[index]]: { highlight: true, value },
          } as RowType;
          forecastRows.push(madeOnRow);
          delete weekly![day];
        }
      });
      break;
    default: madeOnDays.forEach((day, index) => {
      row = forecast[day];
      value = row?.[columnsRange[index]];
      if (row && value !== undefined) {
        const madeOnRow = {
          key: `made on ${day}`,
          reportTitle: day,
          // @ts-ignore
          ...row,
          [columnsRange[index]]: { highlight: true, value },
        } as RowType;
        forecastRows.push(madeOnRow);
        delete forecast[day];
      }
    });
  }

  return forecastRows
}
export const getHistoryRows = (forecast: object) => {
  let historyRows: TAny
  let data: TAny
  let value: TAny

  switch (getSelectedGranularityValue()) {
    case "daily":
      historyRows = Object.keys(forecast)
        .sort((a, b) => moment(a, DAILY_DISPLAY_FORMAT).isAfter(moment(b, DAILY_DISPLAY_FORMAT)) ? 1 : -1)
        .map((day) => {
          const row = forecast[day];
          return { key: `made on ${day} bg-gray`, reportTitle: day, ...row } as RowType;
        });
      break;
    case "weekly":
      data = groupByWeek(forecast)
      value = data.reduce((acc: TAny, item: TAny) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});

      historyRows = Object.keys(value)
        .sort((a, b) => moment(a, WEEKLY_DISPLAY_FORMAT).isAfter(moment(b, WEEKLY_DISPLAY_FORMAT)) ? 1 : -1)
        .map((week) => {
          const row = value[week];
          const dataWeek = groupByWeek(row)
          const valueWeek = dataWeek.reduce((acc, item) => {
            const key = Object.keys(item)[0];
            acc[key] = item[key];
            return acc;
          }, {});

          return { key: `made on ${week} bg-gray`, reportTitle: week, ...valueWeek } as RowType;
        });
      break;
    case "monthly":
      data = groupByMonth({data: forecast})
      value = data.reduce((acc: TAny, item: TAny) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});

      historyRows = Object.keys(value)
        .sort((a, b) => moment(a, MONTHLY_DISPLAY_FORMAT).isAfter(moment(b, MONTHLY_DISPLAY_FORMAT)) ? 1 : -1)
        .map((month) => {
          const row = value[month];
          const dataMonth = groupByMonth({data: row})
          const valueMonth = dataMonth.reduce((acc, item) => {
            const key = Object.keys(item)[0];
            acc[key] = item[key];
            return acc;
          }, {});

          return { key: `made on ${month} bg-gray`, reportTitle: month, ...valueMonth } as RowType;
        });
      break;
    default:
      historyRows = Object.keys(forecast)
        .sort((a, b) => moment(a, DAILY_DISPLAY_FORMAT).isAfter(moment(b, DAILY_DISPLAY_FORMAT)) ? 1 : -1)
        .map((day) => {
          const row = forecast[day];
          return { key: `made on ${day} bg-gray`, reportTitle: day, ...row } as RowType;
        });
  }

  return historyRows
}
export const getLeadRows = (leads) => {
  let leadRow
  let data
  let value
  let valueObj

  if (Object.keys(leads).length) {
    switch (getSelectedGranularityValue()) {
      case 'daily':
        leadRow = { key: 'lead highlight', reportTitle: 'Lag', ...leads };
        break;
      case 'weekly':
        data = groupByWeek(leads)
        value = Object.values(data!)

        valueObj = { key: 'lead highlight', reportTitle: 'Lag', value };

        leadRow = {
          key: valueObj.key,
          reportTitle: valueObj.reportTitle,
          ...valueObj.value.reduce((acc, val) => ({ ...acc, ...val }), {})
        };
        break;
      case 'monthly':
        data = groupByMonth({data: leads})
        value = Object.values(data!)

        valueObj = { key: 'lead highlight', reportTitle: 'Lag', value };

        leadRow = {
          key: valueObj.key,
          reportTitle: valueObj.reportTitle,
          ...valueObj.value.reduce((acc, val) => ({ ...acc, ...val }), {})
        };
        break;
      default: leadRow = { key: 'lead highlight', reportTitle: 'Lag', ...leads };
    }
  } else {
    leadRow = {}
  }
  return leadRow
}
export const getMetricRows = (forecastType: TForecastTableType, horizon: string, lag: number, metrics, columnsRange: string[]) => {
  let metricRows: TAny;
  let horizonFirstMonth:string;
  let value: string | number;
  let data: TAny;
  let metricsKey: TAny;

  if (forecastType === 'over_history') {
    let selectedDate: moment.Moment
    let lagDay: string

    switch (getSelectedGranularityValue()) {
      case "daily":
        selectedDate = moment(horizon).add(lag - 1, 'day');
        lagDay = selectedDate.format(DAILY_DISPLAY_FORMAT);
        metricRows = Object.keys(metrics).map((key) => {
          const value = metrics[key][lagDay];

          return {
            key: `metrics ${key}`,
            reportTitle: key,
            ...metrics[key],
            [lagDay]: { highlight: value !== undefined, value },
          };
        });
        break
      case "weekly":
        selectedDate = moment(horizon).add(lag - 1, 'week');
        lagDay = selectedDate.format(WEEKLY_DISPLAY_FORMAT);

        metricRows = Object.keys(metrics).map((key) => {
          data = groupByWeek(metrics[key])
          metricsKey = data.reduce((acc: TAny, item: TAny) => {
            const key = Object.keys(item)[0];
            acc[key] = item[key];
            return acc;
          }, {});

          return {
            key: `metrics ${key}`,
            reportTitle: key,
            ...metricsKey,
            [lagDay]: { highlight: value !== undefined, value },
          };
        });
        break
      case "monthly":
        selectedDate = moment(horizon).add(lag - 1, 'month');
        lagDay = selectedDate.format(MONTHLY_DISPLAY_FORMAT);

        metricRows = Object.keys(metrics).map((key) => {
          data = groupByMonth(metrics[key])
          metricsKey = data.reduce((acc: TAny, item: TAny) => {
            const key = Object.keys(item)[0];
            acc[key] = item[key];
            return acc;
          }, {});

          return {
            key: `metrics ${key}`,
            reportTitle: key,
            ...metricsKey,
            [lagDay]: { highlight: value !== undefined, value },
          };
        });
        break
      default:
        selectedDate = moment(horizon).add(lag - 1, 'day');
        lagDay = selectedDate.format(DAILY_DISPLAY_FORMAT);
        metricRows = Object.keys(metrics).map((key) => {
          const value = metrics[key][lagDay];

          return {
            key: `metrics ${key}`,
            reportTitle: key,
            ...metrics[key],
            [lagDay]: { highlight: value !== undefined, value },
          };
        });
        break
    }
  } else {
    switch (getSelectedGranularityValue()) {
      case "daily":
        metricRows = Object.keys(metrics).map((key) => {
          horizonFirstMonth = columnsRange[0];
          value = metrics[key][horizonFirstMonth];
          return {
            key: `metrics ${key}`,
            reportTitle: key,
            ...metrics[key],
            [horizonFirstMonth]: { highlight: value !== undefined, value: metrics[key][horizonFirstMonth] },
          };
        });
        break;
      case "weekly":
        metricRows = Object.keys(metrics).map((key) => {
          horizonFirstMonth = columnsRange[0];
          value = metrics[key][horizonFirstMonth];

          data = groupByWeek(metrics[key])
          metricsKey = data.reduce((acc: TAny, item: TAny) => {
            const key = Object.keys(item)[0];
            acc[key] = item[key];
            return acc;
          }, {});

          return {
            key: `metrics ${key}`,
            reportTitle: key,
            ...metricsKey,
            [horizonFirstMonth]: { highlight: value !== undefined, value: metricsKey[horizonFirstMonth] },
          };
        });
        break;
      case "monthly":
        metricRows = Object.keys(metrics).map((key) => {
          horizonFirstMonth = columnsRange[0];
          value = metrics[key][horizonFirstMonth];

          data = groupByMonth({data: metrics[key], isNumber: key === 'MAE'|| key === 'RMSE' })
          metricsKey = data.reduce((acc: TAny, item: TAny) => {
            const key = Object.keys(item)[0];
            acc[key] = item[key];
            return acc;
          }, {});

          return {
            key: `metrics ${key}`,
            reportTitle: key,
            ...metricsKey,
            [horizonFirstMonth]: { highlight: value !== undefined, value: metricsKey[horizonFirstMonth] },
          };
        });
        break;
      default:
        metricRows = Object.keys(metrics).map((key) => {
          horizonFirstMonth = columnsRange[0];
          value = metrics[key][horizonFirstMonth];

          return {
            key: `metrics ${key}`,
            reportTitle: key,
            ...metrics[key],
            [horizonFirstMonth]: { highlight: value !== undefined, value: metrics[key][horizonFirstMonth] },
          };
        });
    }
  }

  return metricRows
}
export const getFvaRow = (key: string, reportTitle: string, inputData: TAny) => {
  let data: TAny
  let value: TAny
  switch (getSelectedGranularityValue()) {
    case 'daily': return { 'key': key, 'reportTitle': reportTitle, ...inputData };
    case 'weekly':
      data = groupByWeek(inputData)
      value = data.reduce((acc: TAny, item: TAny) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});

      return { 'key': key, 'reportTitle': reportTitle, ...value };
    case 'monthly':
      data = groupByMonth({data: inputData})
      value = data.reduce((acc: TAny, item: TAny) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc;
      }, {});
      return { 'key': key, 'reportTitle': reportTitle, ...value };
    default: return { 'key': key, 'reportTitle': reportTitle, ...inputData };
  }
}


type IProps = {
  date: string,
  value: number
}

/**
 * Distribute data
 * @param value
 * @param count
 */
const eventuallyDivision = (value: number, count: number) => {
  const baseValue = Math.floor(value / count);
  const remainder = value % count;

  const result = Array(count).fill(baseValue);

  for (let i = 0; i < remainder; i += 1) {
    result[i] += 1;
  }

  return result;
}

/**
 * Get week dates
 * @param weekRange
 */
const weekDates = (weekRange: string) => {
  const [from, to] = weekRange.split('->');

  const startOfWeek = new Date(from);
  const endOfWeek = new Date(to);

  const week = [];

  for (let d = startOfWeek; d <= endOfWeek; d.setDate(d.getDate() + 1)) {
    // @ts-ignore
    week.push(moment(d, DAILY_DATE_FORMAT).format(DAILY_DATE_FORMAT));
  }

  return week;
}

const weekDateAdjustments = (weekRange: string) => {
  try {
    const [from, to] = weekRange.split('->');

    const year = new Date().getFullYear()

    const startOfWeek = new Date(`${year}-${from}`);
    const endOfWeek = new Date(`${year}-${to}`);

    const week = [];

    for (let d = startOfWeek; d <= endOfWeek; d.setDate(d.getDate() + 1)) {
      // @ts-ignore
      week.push(moment(d, DAILY_DATE_FORMAT).format(DAILY_DATE_FORMAT));
    }


    return week;
  } catch (e){
    console.error(e)
    return []
  }
}

const getDaysInMonth = (year: number, month: number) => {
  // Create a Moment object for the first day of the given month and year
  const firstDayOfMonth = moment({ month, year, date: 1 });

  // Get the last day of the month using the `endOf` method
  const lastDayOfMonth = firstDayOfMonth.endOf('month');

  // Calculate the total number of days by subtracting the first day from the last day and adding 1
  return lastDayOfMonth.diff(firstDayOfMonth, 'days') + 1;
};

const getDaysInMonthAdjustment = (year: number, month: number): number => {
  const firstDayOfMonth = moment({ year, month: month - 1, date: 1 });
  const lastDayOfMonth = firstDayOfMonth.endOf('month');
  return lastDayOfMonth.date();
};

/**
 * Get month dates
 * @param date
 */
const monthDates = (date) => {
  const year = date.getFullYear();
  const m = date.getMonth();
  const month = m + 1;
  const totalDays = getDaysInMonth(year, month);

  const dates = [];
  for (let i = 1; i <= totalDays; i += 1) {
    // @ts-ignore
    dates.push(moment(`${year}-${month}-${i}`, DAILY_DATE_FORMAT).format(DAILY_DATE_FORMAT));
  }

  return dates;
}

const monthDateAdjustments = (date) => {
  const year = date.getFullYear();
  const m = date.getMonth();
  const month = m + 1;
  const totalDays = getDaysInMonthAdjustment(year, month);

  const dates = [];
  for (let i = 1; i <= totalDays; i += 1) {
    // @ts-ignore
    dates.push(moment(`${year}-${month}-${i}`, DAILY_DATE_FORMAT).format(DAILY_DATE_FORMAT));
  }

  return dates;
}

/**
 * Generating data by granularity
 * @param param
 */
export function generateDateValueMap(param: IProps) {
  let dates = [];

  if (getSelectedGranularityValue() === 'daily') {
    return {
      date: param.date,
      value: param.value
    }
  }

  if (getSelectedGranularityValue() === 'weekly') {
    dates = weekDates(param.date);
  } else if (getSelectedGranularityValue() === 'monthly') {
    dates = monthDates(new Date(param.date));
  } else {
    throw new Error('Invalid granularity. should be one of "monthly", "weekly", "daily"');
  }

  const count = dates.length;

  const values = eventuallyDivision(param.value, count);

  return dates.map((date, index) => ({
    date,
    value: values[index]
  }))
}

export function generateDateValueMessageMap(param: IPayload):IPayload[] {
  let dates = [];

  if (getSelectedGranularityValue() === 'weekly') {
    dates = weekDateAdjustments(param?.date);
  } else if (getSelectedGranularityValue() === 'monthly') {
    dates = monthDateAdjustments(new Date(param?.date));
  } else {
    throw new Error('Invalid granularity. should be one of "monthly", "weekly"');
  }

  const count = dates.length;
  const values = eventuallyDivision(param.value, count);

  return dates.map((date, index) => ({
    date,
    value: values[index],
    message: param?.message
  }))
}

export const findDuplicates = (data: IPayloadProps[] | undefined) => {
  const seen = {};
  const duplicates: IPayloadProps[] = [];

  data!.forEach(item => {
    const key = `${item.branch_values}-${item.item}-${item.reference}`;

    if (seen[key]) {
      duplicates.push(item);
    } else {
      seen[key] = true;
    }
  });

  return duplicates;
};

export const removeDuplicatesWithLastItemKept = (data: IPayloadProps[] | undefined) => {
  const seen = new Set();
  const cleanedData: IPayloadProps[] = [];

  for (let i = data!.length - 1; i >= 0; i-=1) {
    const item = data![i];
    const key = `${item.branch_values}-${item.item}-${item.reference}`;

    if (!seen.has(key)) {
      cleanedData.push(item);
      seen.add(key);
    }
  }

  return cleanedData;
};

export const handleHorizonList = () => {
  switch (getSelectedGranularityValue()) {
    case "daily": return dailyHorizon
    case "weekly": return weeklyHorizon
    case "monthly": return monthlyHorizon
    default: return dailyHorizon
  }
}

export const handleHorizonSelection = (horizon: number) => {
  switch (getSelectedGranularityValue()) {
    case "daily": return horizon
    case "weekly": return horizon * 7
    case "monthly": return horizon * 30
    default: return horizon
  }
}

export interface HorizonOption {
  value: number;
  unit: string;
}

export const defaultHorizonSelection = (): HorizonOption => {
  switch (getSelectedGranularityValue()) {
    case "daily": return { value: 30, unit: 'days' }
    case "weekly": return { value: 4, unit: 'weeks' }
    case "monthly": return { value: 6, unit: 'months' }
    default: return { value: 30, unit: 'days' }
  }
}

export const defaultHorizonUnit = (): string => {
  switch (getSelectedGranularityValue()) {
    case "daily": return 'days'
    case "weekly": return 'weeks'
    case "monthly": return 'months'
    default: return 'days'
  }
}

export const toPayload = (forecast: Record<string, number> | {[ date:string ]: { value:string, message:string }}) => {
  if (!forecast) return [];

  return Object.entries(forecast).map(([key, item]) => {
    return ({
      date: formatDate(key),
      value: Number.parseInt(item?.value || item, 10),
      message: item?.message || undefined
    });
  });
};

export const toAdjPayload = (forecast: Record<string, number> | {[ date:string ]: { value:string, message:string }}) => {
  if (!forecast) return [];

  switch (getSelectedGranularityValue()) {
    case "daily":
      return Object.entries(forecast).map(([key, item]) => {
        return ({
          date: formatDate(key),
          value: Number.parseInt(item?.value || item, 10),
          message: item?.message || undefined
        });
      });
    case "weekly":
      return Object.entries(forecast).map(([key, item]) => {
        return ({
          date: key,
          // @ts-ignore
          value: Number.parseInt(item?.value || item, 10),
          // @ts-ignore
          message: item?.message || undefined
        });
      })
        .map((payload) => generateDateValueMessageMap(payload))
        .flat()
    case "monthly":
      return Object.entries(forecast).map(([key, item]) => {
        return ({
          date: key,
          // @ts-ignore
          value: Number.parseInt(item?.value || item, 10),
          // @ts-ignore
          message: item?.message || undefined
        });
      }).map((payload) => generateDateValueMessageMap(payload))
        .flat();
    default:
      return Object.entries(forecast).map(([key, item]) => {
        return ({
          date: formatDate(key),
          value: Number.parseInt(item?.value || item, 10),
          message: item?.message || undefined
        });
      });
  }
};

export const getTemplateByGranularity = (): ITemplateGranularity => {
  const prefix = '/templates';
  const index = Storage.getGranularity()
  switch (index) {
    case '1': return {
      actual  : { path: `${prefix}/daily/actual-daily.xlsx`  , name: 'actual-daily.xlsx' },
      forecast: { path: `${prefix}/daily/forecast-daily.xlsx`, name: 'forecast-daily.xlsx' },
      budget  : { path: `${prefix}/daily/budget-daily.xlsx`  , name: 'budget-daily.xlsx' }
    }
    case '2': return {
      actual  : { path: `${prefix}/weekly/actual-weekly.xlsx`  , name: 'actual-weekly.xlsx' },
      forecast: { path: `${prefix}/weekly/forecast-weekly.xlsx`, name: 'forecast-weekly.xlsx' },
      budget  : { path: `${prefix}/weekly/budget-weekly.xlsx`  , name: 'budget-weekly.xlsx' }
    }
    case '3': return {
      actual  : { path: `${prefix}/monthly/actual-monthly.xlsx`  , name: 'actual-monthly.xlsx' },
      forecast: { path: `${prefix}/monthly/forecast-monthly.xlsx`, name: 'forecast-monthly.xlsx' },
      budget  : { path: `${prefix}/monthly/budget-monthly.xlsx`  , name: 'budget-monthly.xlsx' }
    }
    default: return  {
      actual  : { path: `${prefix}/daily/actual-daily.xlsx`  , name: 'actual-daily.xlsx' },
      forecast: { path: `${prefix}/daily/forecast-daily.xlsx`, name: 'forecast-daily.xlsx' },
      budget  : { path: `${prefix}/daily/budget-daily.xlsx`  , name: 'budget-daily.xlsx' }
    }
  }
}

const dates = [
  {'12/01/2024': 40},
  {'12/02/2024': 60},
  {'12/03/2024': 120},
  {'12/04/2024': 40},
  {'12/05/2024': 20},
  {'12/06/2024': 100},
  {'12/07/2024': 110},
  {'12/08/2024': 150},
  {'12/09/2024': 130},
  {'12/10/2024': 320},
  {'12/11/2024': 120},
  {'12/12/2024': 220},
  {'12/13/2024': 300},
  {'12/14/2024': 400},
  {'12/15/2024': 510},
  {'12/16/2024': 210},
  {'12/17/2024': 170},
  {'12/18/2024': 123},
  {'12/19/2024': 143},
  {'12/20/2024': 123}
]
const getDates = () => {
  return dates.reduce((acc: TAny, item: TAny) => {
    const key = Object.keys(item)[0];
    acc[key] = item[key];
    return acc;
  }, {});
}

const branchData = (keys:string[]) => keys.reduce((acc, key) => {
  acc[key] = `${key}-name`;
  return acc;
}, {});

export const downloadActualTemplate = () => {
  try {
    const branches = Storage.getSelectedTreeItemBranches()
    const keys:string[] = Array.from(branches)

    const wsData = [{
      ...branchData(keys),
      description : 'Item description',
      ...getDates()
    }];

    const ws= XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Daily');

    const payload = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([payload], { type: "application/octet-stream" }), `ActualTemplate.xlsx`);
  } catch (e) {
    console.error(e)
  }
};

export const downloadForecastTemplate = () => {
  try {
    const branches = Storage.getSelectedTreeItemBranches()
    const keys:string[] = Array.from(branches)

    const wsData = [{
      made_on: '12/01/2024',
      ...branchData(keys),
      description : 'Item description',
      ...getDates()
    }];

    const ws= XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Daily');

    const payload = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([payload], { type: "application/octet-stream" }), `ForecastTemplate.xlsx`);
  } catch (e) {
    console.error(e)
  }
};

export const downloadBudgetTemplate = () => {
  try {
    const branches = Storage.getSelectedTreeItemBranches()
    const keys:string[] = Array.from(branches)

    const wsData = [{
      ...branchData(keys),
      description : 'Item description',
      ...getDates()
    }];

    const ws= XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Daily');

    const payload = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([payload], { type: "application/octet-stream" }), `ActualTemplate.xlsx`);
  } catch (e) {
    console.error(e)
  }
};

export const getGranularityData = [
  {
    icon  : BsCalendar,
    title : 'Daily',
    format: 'yyyy-mm-dd',
    index : '1',
  },
  {
    icon  : BsCalendarWeek,
    title : 'Weekly',
    format: 'yyyy-ww',
    index : '2',
  },
  {
    icon  : BsCalendarMonth,
    title : 'Monthly',
    format: 'yyyy-mm',
    index : '3',
  }
]