type kType = {
  DEFAULT_API: string;
  TOKEN_STORAGE_KEY: string;
  REFRESH_TOKEN_STORAGE_KEY: string;
  USER_ROLE_STORAGE: string;
  USER_ID: string;
  USER_NAME: string;
  USER_EMAIL: string;
  TREE_SELECTION_KEY: string;
  SELECTED_TREE_KEY: string;
  EXPANDED_TREE_KEY: string;
  GRANULARITY_KEY: string;
  HORIZON_KEY: string;
  NGINX_PORT: string;
  HORIZON_INDEX_KEY: string;
  SELECTED_TREE_ITEM_ID: string;
  SELECTED_TREE_ITEM_BRANCHES: string;
  ADJUSTMENT_LOCK_DAYS: string;
};

const keys: kType = {
  DEFAULT_API: process.env.DEFAULT_API as string || 'https://barbadosdevback.xeco.be',
  TOKEN_STORAGE_KEY: process.env.TOKEN_STORAGE_KEY as string || 'token_storage_key',
  REFRESH_TOKEN_STORAGE_KEY: process.env.REFRESH_TOKEN_STORAGE_KEY as string || 'refresh_token_storage_key',
  USER_ROLE_STORAGE: process.env.USER_ROLE_STORAGE  as string || 'user_role_storage',
  USER_ID: process.env.USER_ID as string || 'user_id',
  USER_NAME: process.env.USER_NAME as string || 'user_name',
  USER_EMAIL: process.env.USER_EMAIL as string || 'user_email',
  TREE_SELECTION_KEY: process.env.TREE_SELECTION_KEY as string || 'tree_selection_key',
  SELECTED_TREE_KEY: process.env.SELECTED_TREE_KEY  as string || 'selected_tree_key',
  EXPANDED_TREE_KEY: process.env.EXPANDED_TREE_KEY  as string || 'expanded_tree_key',
  GRANULARITY_KEY: process.env.GRANULARITY_KEY  as string || 'granularity_key',
  HORIZON_KEY: process.env.HORIZON_KEY  as string || 'horizon_key',
  NGINX_PORT: process.env.NGINX_PORT  as string || '83',
  HORIZON_INDEX_KEY: process.env.HORIZON_INDEX_KEY  as string || 'horizon_index',
  SELECTED_TREE_ITEM_ID: process.env.SELECTED_TREE_ITEM_ID  as string || 'selected_tree_item_id',
  SELECTED_TREE_ITEM_BRANCHES: process.env.SELECTED_TREE_ITEM_BRANCHES as string || 'selected_tree_item_branches',
  ADJUSTMENT_LOCK_DAYS: process.env.ADJUSTMENT_LOCK_DAYS as string || 'adjustment_lock_days',
};
export default keys;