import {
  Button,
  Form,
  Input, message,
  Select
} from "antd";
import React, { useState } from 'react';
import ModalWrapper from 'app/__portions/ModalWrapper';
import ENDPOINTS from "app/Services/endpoints";
import Api from "app/Services";

type Props = {
  visible   : boolean,
  setVisible: (boolean) => void
  reload    : () => void
}

const DialogAddTree = ({visible, setVisible, reload}:Props) => {
  const [form] = Form.useForm();
  const [performing, setPerforming] = useState(false);

  const initialValues = {
    name    : '',
    branches: [],
  }
  const onClose = () => {
    setVisible(false)
  };

  const onSaveTree = async (values) => {
    const { error } = await Api.post(`${ENDPOINTS.TREE_CRU}`, values);

    if (error) {
      if (
        Object.keys(error).length &&
        Object.getPrototypeOf(error) === Object.prototype
      ) {
        Object.keys(error).forEach((err) => {
          message.error(error[err][0]);
        });
        setPerforming(false);
        return;
      }
      message.error('Failed to update user information. Please try again');
      setPerforming(false);
      return;
    }

    setPerforming(false);
    onClose()
    reload()
  };

  const onFinish = async (values) => {
    setPerforming(true);
    await onSaveTree(values);
  };

  return (
    <ModalWrapper width={450} visible={visible} onCancel={onClose}>
      <h3 className='font-bold'>Add tree</h3>
      <Form
        form={form}
        layout="vertical"
        requiredMark
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <div className="flex flex-col items-center justify-between">
          <Form.Item
            label="Name"
            required
            tooltip="Name is required"
            name="name"
            className="w-full"
            hasFeedback
            rules={[
              { required: true, message: "Please input the tree name!" },
            ]}
          >
            <Input
              placeholder="Name"
              className="w-full border-none border-1 rounded-sm p-1"
            />
          </Form.Item>
          <Form.Item
            label="Branches"
            required
            tooltip="Branches is required"
            name="branches"
            className="w-full"
            hasFeedback
            rules={[
              { required: true, message: "Please input tree branches!" },
            ]}
          >
            <Select
              mode="tags"
              size='large'
              tokenSeparators={[',']}
              className="w-full border-none rounded-sm p-0"
            />
          </Form.Item>
        </div>

        <div className="flex items-center justify-between">
          <Button
            type="dashed"
            className="rounded-md w-full btn-height px-3 mr-2"
            disabled={performing}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            className="rounded-md w-full btn-height px-3 mr-2"
            disabled={performing}
            loading={performing}
          >
            Save
          </Button>
        </div>
      </Form>
    </ModalWrapper>
  );
};

export default DialogAddTree;
